import { IPricing } from '@/features/candidateflow/types';
import { fetcher } from '../index';

const APP_UID = '89689e4c-cc5a-4ca4-9d83-15f7a2ab95e4';

export const getDefaultPricings = async (task: string): Promise<IPricing[]> => {
	return await fetcher(`/apps/${APP_UID}/data/tasks/${task}`);
};

export const getSupplierPricings = async (task: string, supplier: string) => {
	return await fetcher(`/apps/${APP_UID}/data/tasks/${task}/suppliers/${supplier}`);
};

export const createPricing = async (task: string, pricing: IPricing) => {
	return await fetcher(`/apps/${APP_UID}/data/tasks/${task}`, {
		method: 'POST',
		body: JSON.stringify({ pricing }),
	});
};

export const createSubpricing = async (parent: string, supplier: string, pricing: Partial<IPricing>) => {
	return await fetcher(`/apps/${APP_UID}/data/${parent}`, {
		method: 'POST',
		body: JSON.stringify({ ...pricing, supplier }),
	});
};

export const togglePricingRelation = async (uid: string, supplier: string) => {
	return await fetcher(`/apps/${APP_UID}/data/${uid}/suppliers/${supplier}`, {
		method: 'POST',
	});
};

export const updatePricing = async (uid: string, pricing: Partial<IPricing>) => {
	return await fetcher(`/apps/${APP_UID}/data/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({ ...pricing }),
	});
};

export const deletePricing = async (uid: string) => {
	return await fetcher(`/apps/${APP_UID}/data/${uid}`, {
		method: 'DELETE',
	});
};
