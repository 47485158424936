import { IOption } from '@/features/datasources/types';
import { HEX_OPACITY } from '@/utils/constants';

export const OptionPill = ({ option }: { option: IOption }) => {
	return (
		<div
			style={{ backgroundColor: option.color + HEX_OPACITY[10], color: option.color }}
			className={'w-fit text-xs px-2 py-1 rounded-xl select-none'}
		>
			<p>{option.text}</p>
		</div>
	);
};
