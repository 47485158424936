import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/shared/components/ui/dropdown';
import { suppliersCollection } from '@/utils/api/collections';
import { parseName } from '@/utils/helpers';
import { faCheck, faCheckCircle, faCircleMinus, faMagnifyingGlass, faParachuteBox, faTag } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceBy, findIndex, map } from 'lodash';
import { useEffect, useState } from 'react';
import { getTagsBySources } from '../../../../features/tags/utils/getTagsBySources';
import { ImageWithFallback, ORGANISATION_AVATERS } from '../../../../utils/ImageWithFallback';
import Avatar from '../../../avatar';
import { Loader } from '../../../spinners/loader';

interface ISupplierLine {
	supplier: any;
	selectedContacts: string[];
	selected: boolean;
	readOnly: boolean;
	onToggle(): void;
	onContactToggle(contact: any): void;
}

function SupplierLine({ supplier, selectedContacts, selected, readOnly, onContactToggle, onToggle }: ISupplierLine) {
	const firstContact = supplier?.contacts?.find((s) => s.uid === selectedContacts?.[0]);
	return (
		<div className="grid h-20 w-full grid-cols-12 items-center border-t pl-4">
			<div className="relative col-span-2 ml-0 h-16">
				<ImageWithFallback src={ORGANISATION_AVATERS(supplier?.uid)} fallback={<></>} fill />
			</div>
			<div className="col-span-3">
				<p className="pl-4 text-left text-xs text-gray-400">
					Name: <span className="font-medium text-gray-500">{supplier.name ? supplier.name : supplier.reference}</span>
				</p>
			</div>
			<div className="col-span-2">
				<p className="text-left text-xs text-gray-400">
					Ref: <span className="font-medium text-gray-500">{supplier.reference}</span>
				</p>
			</div>
			<div className="col-span-3">
				{supplier?.contacts?.length ? (
					<div className="w-fit">
						{readOnly ? (
							<div className="flex items-center gap-2 border-b last:border-none">
								{!!selectedContacts.length && (
									<>
										{selectedContacts?.length > 1 ? (
											<Avatar size={25} text={selectedContacts.length.toString()} />
										) : (
											<Avatar size={25} email={firstContact.user.email ?? ''} />
										)}
									</>
								)}
								<p className="select none text-xs font-bold">
									{selectedContacts.length
										? selectedContacts.length > 1
											? `${selectedContacts.length} Selected`
											: parseName(firstContact.user)
										: 'None selected'}
								</p>
							</div>
						) : (
							<DropdownMenu>
								<DropdownMenuTrigger>
									<div className="flex cursor-pointer select-none items-center gap-2 border-b last:border-none">
										<FontAwesomeIcon className="text-gray-300" icon={faCaretDown} />
										{!!selectedContacts.length && (
											<p className="text-xs text-white">
												{selectedContacts?.length > 1 ? (
													<Avatar size={25} text={selectedContacts.length.toString()} />
												) : (
													<Avatar size={25} email={firstContact.user.email ?? ''} />
												)}
											</p>
										)}
										<p className="text-xs font-bold">
											{selectedContacts.length
												? selectedContacts.length > 1
													? ` Selected`
													: parseName(firstContact.user)
												: 'None selected'}
										</p>
									</div>
								</DropdownMenuTrigger>
								<DropdownMenuContent>
									{supplier?.contacts?.map((c) => (
										<DropdownMenuItem
											key={c.uid}
											onClick={(e) => {
												e.stopPropagation();
												onContactToggle(c);
											}}
										>
											<div className="group flex w-full items-center gap-2 border-b last:border-none">
												<p className="text-xs uppercase text-green-600">
													{selectedContacts.includes(c.uid) ? (
														<FontAwesomeIcon icon={faCheck} />
													) : (
														<Avatar border={false} size={25} email={c?.user?.email ?? ''} />
													)}
												</p>
												<p className="text-xs font-medium text-gray-400 group-hover:text-blue-500">{parseName(c.user)}</p>
											</div>
										</DropdownMenuItem>
									))}
								</DropdownMenuContent>
							</DropdownMenu>
						)}
					</div>
				) : (
					<p className="text-xs font-bold">None available</p>
				)}
			</div>
			<div className="col-span-2 mr-2 flex justify-end">
				{!readOnly && (
					<>
						{selected ? (
							<button onClick={onToggle} className="flex w-fit items-center gap-2 rounded-md bg-green-100 px-4 py-2 text-green-600">
								<FontAwesomeIcon icon={faCheck} />
								<p className="text-xs">Selected</p>
							</button>
						) : (
							<button onClick={onToggle} className="flex w-fit items-center gap-2 rounded-md bg-gray-100 px-8 py-2 text-gray-600">
								<p className="text-xs">Select</p>
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
}

interface ISuppliersSelector {
	label?: string;
	includeOnly?: string[];
	selected?: any[];
	onSelect?(suppliers: any[]): void;
	readOnly?: boolean;
}

export default function SuppliersSelector({ label = '', includeOnly, selected = [], onSelect = null, readOnly = false }: ISuppliersSelector) {
	const [_suppliers, setSuppliers] = useState([]);
	const [supplierTags, setSupplierTags] = useState({});
	const [selectedSupplierTags, setSelectedSupplierTags] = useState([]);
	const [query, setQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const suppliers = readOnly ? selected : _suppliers;

	const fetchSupplierTags = async () => {
		const tags = await getTagsBySources(_suppliers.map((v) => v.uid));
		setSupplierTags(tags);
	};

	useEffect(() => {
		fetchSuppliers();
	}, []);

	useEffect(() => {
		if (suppliers?.length > 0) {
			fetchSupplierTags();
		}
	}, [suppliers]);

	const fetchSuppliers = async () => {
		const suppliers = await suppliersCollection();
		if (Array.isArray(includeOnly)) {
			const filteredSuppliers = suppliers.filter((s) => includeOnly.includes(s.uid));
			setSuppliers(filteredSuppliers);
		} else {
			setSuppliers(suppliers);
		}
		setLoading(false);
	};

	const isSelected = (uid: string) => {
		return selected.find((m) => m.uid === uid);
	};

	let filteredData =
		query === ''
			? suppliers
			: suppliers.filter(({ name, reference }) =>
					[
						name?.toLowerCase()?.replace(/\s+/g, ''),
						typeof reference === 'string' ? reference?.toLowerCase()?.replace(/\s+/g, '') : '',
					].some((v) => {
						return String(v).includes(query.toLowerCase().replace(/\s+/g, ''));
					})
			  );

	if (selectedSupplierTags.length > 0) {
		filteredData = filteredData.filter((fd) => {
			const tags = supplierTags?.[fd.uid] || [];
			return tags?.some((tag) => map(selectedSupplierTags, 'uid').includes(tag.uid));
		});
	}

	const getUniqueTags = () => {
		const result = {};
		Object.keys(supplierTags).forEach((index) => {
			const tags = supplierTags[index];
			tags.forEach((tag) => {
				result[tag.uid] ??= { ...tag, source: undefined };
			});
		});
		return Object.values(result);
	};

	const selectAllFromSelection = () => {
		onSelect(filteredData.map((t) => ({ ...t, checked: true })));
	};

	const deselectAllFromSelection = () => {
		onSelect(filteredData.filter((v) => !filteredData.some((fd) => fd.uid === v.uid)));
	};

	const onToggle = (supplier: any) => {
		const clone = [...selected];
		const index = findIndex(clone, (v) => v.uid === supplier.uid);
		if (index > -1) {
			clone.splice(index, 1);
		} else {
			clone.push(supplier);
		}
		onSelect(clone);
	};

	const onContactToggle = (supplier: any) => (contact: any) => {
		let selectedSupplier = selected.find((s) => s.uid === supplier.uid);
		const isSelected = !!selectedSupplier;
		selectedSupplier ??= supplier;
		const contacts = [...(selectedSupplier?.selected_contacts || [])];
		const index = findIndex(contacts, (c) => c.uid === contact.uid);
		if (index > -1) {
			contacts.splice(index, 1);
		} else {
			contacts.push(contact);
		}
		const supplierWithContacts = { ...selectedSupplier, selected_contacts: contacts };
		if (contacts.length > 0 && !!isSelected) {
			onSelect(
				selected.map((s) => {
					if (s.uid === supplierWithContacts.uid) {
						return supplierWithContacts;
					}
					return s;
				})
			);
		} else {
			onToggle(supplierWithContacts);
		}
	};

	const toggleSelectedTags = (tag) => {
		const selectedTags = [...selectedSupplierTags];
		const index = selectedTags.findIndex((r) => r.uid === tag.uid);
		if (index > -1) {
			selectedTags.splice(index, 1);
		} else {
			selectedTags.push(tag);
		}
		setSelectedSupplierTags(selectedTags);
	};

	if (loading) {
		return <Loader />;
	}

	const tags = getUniqueTags();

	return (
		<div className="w-full">
			<p className="mb-2 text-xs font-medium text-gray-500">{label}</p>
			<div className="rounded-bl-md rounded-br-md bg-white">
				<div className="grid grid-cols-12 rounded-tl-md rounded-tr-md bg-[#E5EBF2] p-4 text-xs font-medium">
					<div className="col-span-2">
						<p className="text-gray-400">Company</p>
					</div>
					<div className="col-span-3">
						<p className="text-gray-400"></p>
					</div>
					<div className="col-span-2">
						<p className="text-gray-400">Reference</p>
					</div>
					<div className="col-span-3">
						<p className="text-gray-400">Contact persons</p>
					</div>
					<div className="col-span-2">
						<p className="text-gray-400"></p>
					</div>
				</div>
				<div className="flex items-center justify-between px-4 py-2">
					<div className="flex items-center gap-2">
						<div className="relative flex items-center gap-2 py-2">
							<FontAwesomeIcon className="absolute left-2" icon={faMagnifyingGlass} />
							<input
								type="text"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								placeholder="Search by Supplier name or VAT number"
								className="w-72 rounded-lg py-2 pl-8 pr-4 text-xs focus:placeholder-gray-400 focus:outline-blue-500"
							/>
						</div>
						<DropdownGeneric
							collection={differenceBy(tags, selectedSupplierTags, (t) => t.uid)}
							renderItem={({ item }: { item: any }) => (
								<div
									onClick={() => toggleSelectedTags(item)}
									style={{ backgroundColor: item.background_color }}
									className="m-1 cursor-pointer rounded-md px-2 py-1 opacity-90 hover:opacity-100"
								>
									<p style={{ color: item.text_color }} className="text-xs">
										{item.name}
									</p>
								</div>
							)}
							button={
								<div className="flex items-center gap-2 text-gray-400">
									<FontAwesomeIcon icon={faTag} size="lg" />
									<p className="text-xs">Filter by tag</p>
								</div>
							}
						/>
						<div className="flex items-center gap-1">
							{selectedSupplierTags.map((tag: any) => (
								<div
									onClick={() => toggleSelectedTags(tag)}
									key={tag.uid}
									style={{ backgroundColor: tag.background_color }}
									className="cursor-pointer rounded-md px-2 py-1 opacity-90 hover:opacity-100"
								>
									<p style={{ color: tag.text_color }} className="text-xs">
										{tag.name}
									</p>
								</div>
							))}
						</div>
					</div>
					{!readOnly && (
						<div className="mr-3 flex items-center justify-center text-sm">
							{selected.length < suppliers.length ? (
								<button
									className="flex items-center gap-2 rounded-md bg-gray-100 px-6 py-2 text-gray-600"
									onClick={selectAllFromSelection}
								>
									<FontAwesomeIcon icon={faCheckCircle} size="lg" />
									<p>Select all</p>
								</button>
							) : (
								<button
									className="flex items-center gap-2 rounded-md bg-red-100 px-6 py-2 text-red-800"
									onClick={deselectAllFromSelection}
								>
									<FontAwesomeIcon icon={faCircleMinus} size="lg" />
									<p>Deselect all</p>
								</button>
							)}
						</div>
					)}
				</div>
				<div className="flex max-h-96 min-h-[24rem] flex-col overflow-y-auto">
					{!filteredData.length && (
						<div className="flex w-full flex-col items-center justify-center gap-2">
							<FontAwesomeIcon icon={faParachuteBox} size="2x" />
							<p className="text-center">No suppliers was found</p>
						</div>
					)}
					{filteredData.map((supplier) => {
						const selectedSupplier = selected.find((s) => s.uid === supplier.uid);
						return (
							<SupplierLine
								key={supplier.uid}
								supplier={supplier}
								selectedContacts={selectedSupplier?.selected_contacts?.map((cp) => cp.uid) || []}
								selected={isSelected(supplier.uid)}
								readOnly={readOnly}
								onToggle={() => onToggle(supplier)}
								onContactToggle={onContactToggle(supplier)}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
