import { selectedSpaceAtom } from '@/atoms/spaces';
import { selectedTask } from '@/atoms/tasks';
import { FilePreview } from '@/components/FilePreview';
import { BasicButton } from '@/components/buttons/BasicButton';
import { DownloadCVParams } from '@/types/cvpartner';
import { downloadCV } from '@/utils/api/cvpartners';
import { useUser } from 'hooks/cvpartner/useUser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { ICell } from '../../Cell';

export const CVCell = (props: ICell) => {
	const cvpId = props.record?.cvp_id;
	const { user: profile } = useUser(cvpId);
	const [open, setOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cv, setCv] = useState<string>();
	const space = useRecoilValue(selectedSpaceAtom);
	const task = useRecoilValue(selectedTask);
	const cvTemplateField = task?._template?.fields?.find((f) => f.type === 'cv_templates');
	const cvTemplate = task?.[cvTemplateField?.key];
	const router = useRouter();
	useEffect(() => {
		if (open && !cv && !!profile) {
			getCV();
		}
	}, [open]);

	const getCV = async () => {
		setIsLoading(true);
		const cv = profile?.default_cv_id;
		const user = profile?.id;
		const lang = space.locale?.split?.('-')?.[1]?.toLowerCase() ?? 'int';
		const props: DownloadCVParams = {
			cv,
			user,
			filetype: 'pdf',
			lang,
		};
		if (cvTemplate || profile?.default_cv_template_id) {
			props['template'] = cvTemplate ?? profile?.default_cv_template_id;
		}
		try {
			const res = await downloadCV(props);
			if (res?.cv) {
				setCv(res?.cv);
			} else {
				toast.error('No CV found');
			}
		} catch (error) {
			toast.error('No CV found');
		} finally {
			setIsLoading(false);
		}
	};

	if (!profile && !isLoading) {
		return <p className="text-gray-200">No CV</p>;
	}

	return (
		<div>
			<FilePreview
				header={{
					title: profile.name,
					handlers: (
						<BasicButton variant="primary" className="" onClick={() => router.replace(`/people/${props.record.uid}`)}>
							Go to profile
						</BasicButton>
					),
				}}
				open={!isLoading && open}
				setOpen={setOpen}
				loading={isLoading}
				uris={cv ? [{ uri: cv, mimetype: 'application/pdf' }] : undefined}
			/>
			<button
				type="button"
				className={`underline ${isLoading ? 'animate-wiggle-pulse-blue' : ''}`}
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
			>
				View CV
			</button>
		</div>
	);
};
