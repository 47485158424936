import { fetcher } from '..';

export const downloadCV = async ({
	cv,
	user,
	lang,
	filetype,
	template,
}: {
	cv: string;
	user: string;
	lang: string;
	filetype: string;
	template?: string;
}): Promise<{ cv: string }> => {
	return await fetcher(`/cvpartners/cvs/${cv}/download/${user}/${lang}/${filetype}${template ? `/${template}` : ''}`);
};
