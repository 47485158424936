import { IField } from '@/features/fields/types';
import {
	faBarsProgress,
	faBuilding,
	faCalendar,
	faCalendarRange,
	faChevronCircleDown,
	faCircleDollar,
	faCirclePlus,
	faClock,
	faEyes,
	faFiles,
	faHashtag,
	faIdCard,
	faList,
	faMoneyBill,
	faMoneyCheckDollar,
	faNoteSticky,
	faPartyHorn,
	faPeople,
	faPercent,
	faPhone,
	faPrint,
	faRotate,
	faSignature,
	faSlider,
	faSlidersSimple,
	faStars,
	faTags,
	faText,
	faToggleOff,
	faUserCrown,
	faUserTie,
	faUsers,
} from '@fortawesome/pro-regular-svg-icons';

export const FieldTypes: IField[] = [
	{
		key: 'text',
		type: 'text',
		label: 'Text',
		required: false,
		placeholder: '',
	},
	{
		key: 'textarea',
		type: 'textarea',
		label: 'Textarea',
		required: false,
	},
	{
		key: 'number',
		type: 'number',
		label: 'Number',
		required: false,
		placeholder: '',
	},
	{
		key: 'date',
		type: 'date',
		label: 'Date',
		required: false,
		placeholder: '',
	},
	{
		key: 'date_range',
		type: 'date_range',
		label: 'Date Range',
		required: false,
		placeholder: '',
	},
	{
		key: 'multi_date_range',
		type: 'multi_date_range',
		label: 'Multi Date Range',
		required: false,
		placeholder: '',
	},
	{
		key: 'money',
		type: 'money',
		label: 'Money',
		required: false,
	},
	{
		key: 'select',
		type: 'select',
		label: 'Select',
		required: false,
		placeholder: '',
	},
	{
		key: 'multiselect',
		type: 'multiselect',
		label: 'Multi-select',
		required: false,
		placeholder: '',
	},
	{
		key: 'phone',
		type: 'phone',
		label: 'Phone',
		required: false,
		placeholder: '',
	},
	{
		key: 'attachments',
		type: 'attachments',
		label: 'Files',
		required: false,
	},
	{
		key: 'price_selector',
		type: 'price_selector',
		label: 'Price selector',
		required: false,
	},
	{
		key: 'price_range',
		type: 'price_range',
		label: 'Price range',
		required: false,
	},
	{
		key: 'progress_bar',
		type: 'progress_bar',
		label: 'Progress bar',
		required: false,
	},
	{
		key: 'suppliers',
		type: 'suppliers',
		label: 'Suppliers',
		required: false,
	},
	{
		key: 'agreement_owner',
		type: 'agreement_owner',
		label: 'Agreement owner',
		required: false,
	},
	{
		key: 'fte',
		type: 'fte',
		label: 'FTE',
		required: false,
	},
	{
		key: 'extended_task',
		type: 'extended_task',
		label: 'Extended task',
		required: false,
	},
	{
		key: 'tags',
		type: 'tags',
		label: 'Tags',
		required: false,
	},
	{
		key: 'slider',
		type: 'slider',
		label: 'Slider',
		required: false,
	},
	{
		key: 'toggle',
		type: 'toggle',
		label: 'Toggle',
		required: false,
	},
	{
		label: 'Party',
		key: 'party',
		type: 'party',
		required: false,
	},
	{
		key: 'library',
		type: 'library',
		label: 'Import from library',
		required: false,
	},
	{
		key: 'candidate_status',
		type: 'candidate_status',
		label: 'Candidate status',
		required: false,
	},
];

export const FieldTypeIcons = {
	text: faText,
	textarea: faNoteSticky,
	number: faHashtag,
	date: faCalendar,
	date_range: faCalendar,
	multi_date_range: faCalendarRange,
	money: faMoneyBill,
	select: faChevronCircleDown,
	multiselect: faList,
	phone: faPhone,
	attachments: faFiles,
	price_range: faSlidersSimple,
	pricings: faCircleDollar,
	price_selector: faMoneyCheckDollar,
	progress_bar: faBarsProgress,
	suppliers: faUserTie,
	agreement_owner: faUserCrown,
	fte: faClock,
	extended_task: faRotate,
	cv_templates: faPrint,
	tags: faTags,
	responsibles: faUsers,
	owners: faUserCrown,
	contract_suppliers: faUserTie,
	talents: faStars,
	task_talents: faStars,
	viewers: faEyes,
	slider: faSlider,
	toggle: faToggleOff,
	customer: faBuilding,
	signature: faSignature,
	party: faPartyHorn,
	type: faIdCard,
	people_user: faUserTie,
	people_fullname: faText,
	people_company: faBuilding,
	people_allocation: faPercent,
	library: faCirclePlus,
	candidate_status: faPeople,
};

export const FieldTypeData = {
	text: '',
	textarea: '',
	number: 0,
	date: new Date().toISOString(),
	date_range: [new Date().toISOString(), new Date().toISOString()],
	multi_date_range: [[new Date().toISOString(), new Date().toISOString()]],
	money: 0,
	select: [],
	multiselect: [],
	phone: 4512345678,
	agreement_owner: { name: '', uid: '' },
	fte: 37,
	extended_task: { extended_task_uid: '', continuation_type: '' },
	tags: [],
	cv_templates: [],
	slider: 0,
	toggle: false,
	candidate_status: {},
};
